import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../images/Logo.jpg";
import dateRange from "../images/DateRange.png";
import report from "../images/Report_img.png";
import rupee from "../images/Rupee_Currency.png";
import Chart from "chart.js/auto";
import DatePicker from "../utilities/DatePicker.js";
import "./styles/Dashboard.css";

const Dashboard = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null); // Keep track of the chart instance
  const navigate = useNavigate();

  const [summaryData, setSummaryData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  });

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchAndAggregateData = () => {
      try {
        const platformSaleData =
          JSON.parse(localStorage.getItem("PlatformSale")) || [];
        const purchaseData = JSON.parse(localStorage.getItem("Purchase")) || [];
        const acSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];

        const allTransactions = [
          ...platformSaleData,
          ...purchaseData,
          ...acSaleData,
        ];

        const filteredTransactions = allTransactions.filter((transaction) => {
          const transactionDate = new Date(transaction.currentDate)
            .toISOString()
            .split("T")[0];
          return transactionDate === selectedDate;
        });

        const summaryMap = {};
        filteredTransactions.forEach((tx) => {
          const mark = tx.mark || "Unknown";
          if (!summaryMap[mark]) {
            summaryMap[mark] = {
              mark,
              totalBags: 0,
              totalWeight: 0,
              amt: 0,
              gAmt: 0,
              netAmt: 0,
            };
          }
          summaryMap[mark].totalBags += Number(tx.bags) || 0;
          summaryMap[mark].totalWeight += Number(tx.weight) || 0;
          summaryMap[mark].amt += Number(tx.amount) || 0;
          summaryMap[mark].gAmt += Number(tx.gAmt) || 0;
          summaryMap[mark].netAmt += Number(tx.netAmt) || 0;
        });

        const aggregatedSummary = Object.values(summaryMap);
        setSummaryData(aggregatedSummary);

        const vendorMap = {};
        filteredTransactions.forEach((tx) => {
          const accountName = tx.partyName || "Unknown";
          if (!vendorMap[accountName]) {
            vendorMap[accountName] = {
              accountName,
              totalBags: 0,
              totalWeight: 0,
              totalAmount: 0,
            };
          }
          vendorMap[accountName].totalBags += Number(tx.bags) || 0;
          vendorMap[accountName].totalWeight += Number(tx.weight) || 0;
          vendorMap[accountName].totalAmount += Number(tx.amount) || 0;
        });

        const aggregatedVendors = Object.values(vendorMap);
        setVendorData(aggregatedVendors);

        const labels = aggregatedSummary.map((summary) => summary.mark);
        const weights = aggregatedSummary.map((summary) => summary.totalWeight);
        const bags = aggregatedSummary.map((summary) => summary.totalBags);

        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy(); // Destroy the old chart instance
        }

        if (chartRef.current) {
          chartInstanceRef.current = new Chart(chartRef.current, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Total Bags",
                  data: bags,
                  backgroundColor: "#266DA8",
                  borderColor: "#266DA8",
                  borderWidth: 1,
                },
                {
                  label: "Total Weights (kg)",
                  data: weights,
                  backgroundColor: "#194163",
                  borderColor: "#194163",
                  borderWidth: 1,
                },
              ],
            },
            options: {
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          });
        }
      } catch (error) {
        console.error("Error processing localStorage data:", error);
      }
    };

    fetchAndAggregateData();

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy(); // Clean up the chart when the component unmounts
      }
    };
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  return (
    <div>
      <header className="main-header">
        <img src={logo} alt="Kliq Soft" className="logo" />
      </header>

      <div className="wrap">
        <div className="dashRow group">
          <div
            className="row"
            onClick={toggleDropdown1}
            style={{ position: "relative" }}
          >
            <img src={rupee} alt="Rupee Currency" />
            <p className="DropdownLink">Transactions &#8595;</p>
            {isDropdownOpen1 && (
              <div className="dropdown-menu">
                {/* <NavLink to="transactions">Purchase</NavLink> */}
                <NavLink to="acsale">AC Sale</NavLink>
                <NavLink to="PlatformSale">Platform Sale</NavLink>
              </div>
            )}
          </div>
          <div
            className="row"
            onClick={toggleDropdown2}
            style={{ position: "relative" }}
          >
            <img src={report} alt="Report Image" />
            <p className="DropdownLink">Reports &#8595;</p>
            {isDropdownOpen2 && (
              <div className="dropdown-menu">
                {/* <NavLink to="purchasereport">Purchase Report</NavLink> */}
                {/* <NavLink to="markwisepurchasereport">Markwise Report</NavLink> */}
                <NavLink to="salesreport">Sales Report</NavLink>
              </div>
            )}
          </div>
        </div>

        <div className="dashRow datePicker group">
          <label htmlFor="datePicker">
            <img src={dateRange} alt="Date Range" />
          </label>
          <DatePicker onDateChange={handleDateChange} />
        </div>

        <div className="heading">
          <h2>Cold Storage - Summary</h2>
        </div>

        <div className="dashRow summaryTable" style={{ overflowX: "auto" }}>
          <table className="sum_table">
            <thead>
              <tr>
                <th>Mark</th>
                <th>Total Bags</th>
                <th>Total Weight (kg)</th>
                <th>Amt</th>
                <th>G.Amt</th>
                <th>Net Amt</th>
              </tr>
            </thead>
            <tbody>
              {summaryData.length > 0 ? (
                summaryData.map((summary, index) => (
                  <tr key={index}>
                    <td>{summary.mark}</td>
                    <td>{summary.totalBags}</td>
                    <td>{summary.totalWeight.toFixed(3)}</td>
                    <td>{summary.amt.toFixed(2)}</td>
                    <td>{summary.gAmt.toFixed(2)}</td>
                    <td>{summary.netAmt.toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No data available for the selected date.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <hr />

        <div className="dashRow chart">
          <canvas ref={chartRef}></canvas>
        </div>

        <hr />

        <div className="heading_vendor">
          <h2>Vendor Purchase</h2>
        </div>

        <div
          className="dashRow vendor"
          style={{ overflowX: "auto", marginBottom: "3.5rem" }}
        >
          <table>
            <thead>
              <tr>
                <th>Account Name</th>
                <th>Total Bags</th>
                <th>Total Weight (kg)</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {vendorData.length > 0 ? (
                vendorData.map((vendor, index) => (
                  <React.Fragment key={index}>
                    {/* First row with Account Name */}
                    <tr>
                      <td colSpan="4" style={{ textAlign: "left" }}>
                        {vendor.accountName}
                      </td>
                      {/* <td colSpan="3"></td> */}
                      {/* Empty columns for alignment */}
                    </tr>
                    {/* Second row with Total Bags, Total Weight, Total Amount */}
                    <tr>
                      <td></td>
                      {/* Empty cell for Account Name alignment */}
                      <td>{vendor.totalBags}</td>
                      <td>{vendor.totalWeight.toFixed(3)}</td>
                      <td>{vendor.totalAmount.toFixed(2)}</td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No vendor data available for the selected date.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
