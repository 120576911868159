import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import * as XLSX from "xlsx"; // Import xlsx
import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import react-datepicker CSS
import logo from "../images/Logo.jpg";
import excelIcon from "../images/excel.svg";
import dateRange from "../images/DateRange.png";
import "./styles/AccountAndTransactions.css";
import "./styles/MarkAndDaily.css";

// Import Capacitor Filesystem plugin
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";

function SalesReport() {
  const [salesData, setSalesData] = useState([]);
  const today = new Date();
  const [dateRangeState, setDateRange] = useState([today, today]);
  const [startDate, endDate] = dateRangeState;

  useEffect(() => {
    // Fetch and parse data from localStorage
    const platformSale = JSON.parse(localStorage.getItem("PlatformSale")) || [];
    const acSale = JSON.parse(localStorage.getItem("AcSale")) || [];

    // Combine the data from both sources
    const combinedData = [...platformSale, ...acSale];

    // Helper function to convert DD-MM-YYYY to Date object
    const convertDateFormatToDate = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      return new Date(`${year}-${month}-${day}`);
    };

    // Adjust startDate and endDate to include the entire days
    let adjustedStartDate = startDate ? new Date(startDate) : null;
    let adjustedEndDate = endDate ? new Date(endDate) : null;

    if (adjustedStartDate) adjustedStartDate.setHours(0, 0, 0, 0);
    if (adjustedEndDate) adjustedEndDate.setHours(23, 59, 59, 999);

    // Filter data based on the selected date range
    const filteredData = combinedData.filter((item) => {
      if (!item.billDate) return false;

      const billDate = convertDateFormatToDate(item.billDate);

      // If only startDate is selected
      if (adjustedStartDate && !adjustedEndDate) {
        return billDate >= adjustedStartDate;
      }

      // If only endDate is selected
      if (!adjustedStartDate && adjustedEndDate) {
        return billDate <= adjustedEndDate;
      }

      // If both dates are selected
      if (adjustedStartDate && adjustedEndDate) {
        return billDate >= adjustedStartDate && billDate <= adjustedEndDate;
      }

      return true; // Should not reach here
    });

    // Sort the filtered data by latest date first, then by Bill No.
    const sortedData = [...filteredData].sort((item1, item2) => {
      // Compare dates descending
      const date1 = convertDateFormatToDate(item1.billDate);
      const date2 = convertDateFormatToDate(item2.billDate);

      if (date1 > date2) return -1;
      if (date1 < date2) return 1;

      // Dates are equal, compare Bill No. ascending
      const billNo1 = item1.billNo || item1.billNo2 || "";
      const billNo2 = item2.billNo || item2.billNo2 || "";

      // Attempt to parse Bill No. as numbers
      const num1 = parseInt(billNo1, 10);
      const num2 = parseInt(billNo2, 10);

      if (!isNaN(num1) && !isNaN(num2)) {
        return num1 - num2;
      } else {
        // Compare as strings
        return billNo1.localeCompare(billNo2);
      }
    });

    setSalesData(sortedData);
  }, [startDate, endDate]);

  // Helper function to group items by farmer name
  const groupByFarmer = (data) => {
    return data.reduce((acc, item) => {
      const farmer = item.farmer || "Unknown Farmer";
      if (!acc[farmer]) {
        acc[farmer] = [];
      }
      acc[farmer].push(item);
      return acc;
    }, {});
  };

  // Calculate the total for specific fields for each farmer
  const calculateFarmerTotals = (items) => {
    return items.reduce(
      (totals, item) => {
        totals.bags += parseFloat(item.bags) || 0;
        totals.weight += parseFloat(item.weight) || 0;
        totals.amount += parseFloat(item.amount) || 0;
        totals.gAmt += parseFloat(item.gAmt) || 0;
        totals.netAmt += parseFloat(item.netAmt) || 0;
        return totals;
      },
      { bags: 0, weight: 0, amount: 0, gAmt: 0, netAmt: 0 }
    );
  };

  // Calculate overall totals
  const calculateTotals = (data) => {
    return data.reduce(
      (totals, item) => {
        totals.bags += parseFloat(item.bags) || 0;
        totals.weight += parseFloat(item.weight) || 0;
        totals.amount += parseFloat(item.amount) || 0;
        totals.gAmt += parseFloat(item.gAmt) || 0;
        totals.netAmt += parseFloat(item.netAmt) || 0;
        return totals;
      },
      { bags: 0, weight: 0, amount: 0, gAmt: 0, netAmt: 0 }
    );
  };

  const groupedData = groupByFarmer(salesData);
  const totals = calculateTotals(salesData);

  // Function to handle Excel download
  const downloadExcel = async () => {
    if (salesData.length === 0) {
      alert("No data available to download for the selected date range.");
      return;
    }

    const workbook = XLSX.utils.book_new();
    const worksheetData = [];

    // Define the headers
    const headers = [
      "Farmer Name",
      "Bill Date",
      "Bill No.",
      "Trader Name",
      "Item Name / Grade Name",
      "Mark",
      "Bags",
      "Weight (Kgs)",
      "Rate",
      "Amount",
      "Gunny Amount",
      "Net Amount",
    ];
    worksheetData.push(headers);

    // Iterate over each farmer group
    Object.keys(groupedData).forEach((farmerName) => {
      const farmerData = groupedData[farmerName];
      const farmerTotals = calculateFarmerTotals(farmerData);

      // Add a row for the farmer name
      worksheetData.push([
        farmerName,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      // Add each transaction row
      farmerData.forEach((item) => {
        worksheetData.push([
          "", // Farmer Name column left blank for transactions
          item.billDate,
          item.billNo || item.billNo2 || "",
          item.partyName || "",
          `${item.itemName} / ${item.gradeName}`,
          item.mark || "",
          item.bags || 0,
          parseFloat(item.weight || 0).toFixed(2),
          parseFloat(item.rate || 0).toFixed(2),
          parseFloat(item.amount || 0).toFixed(2),
          parseFloat(item.gAmt || 0).toFixed(2),
          parseFloat(item.netAmt || 0).toFixed(2),
        ]);
      });

      // Add a subtotal row for the farmer
      worksheetData.push([
        `Totals for ${farmerName}`,
        "",
        "",
        "",
        "",
        "",
        farmerTotals.bags,
        farmerTotals.weight.toFixed(2),
        "",
        farmerTotals.amount.toFixed(2),
        farmerTotals.gAmt.toFixed(2),
        farmerTotals.netAmt.toFixed(2),
      ]);

      // Add an empty row for spacing
      worksheetData.push([""]);
    });

    // Add overall totals
    worksheetData.push([
      "Overall Totals",
      "",
      "",
      "",
      "",
      "",
      totals.bags,
      totals.weight.toFixed(2),
      "",
      totals.amount.toFixed(2),
      totals.gAmt.toFixed(2),
      totals.netAmt.toFixed(2),
    ]);

    // Create worksheet from data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Report");

    // Generate base64 string and array buffer
    const base64Excel = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "base64",
    });
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Define file name
    const formattedFromDate = startDate
      ? `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(
          -2
        )}-${`0${startDate.getDate()}`.slice(-2)}`
      : "start";
    const formattedToDate = endDate
      ? `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(
          -2
        )}-${`0${endDate.getDate()}`.slice(-2)}`
      : "end";
    const fileName = `SalesReport_${formattedFromDate}_to_${formattedToDate}.xlsx`;

    try {
      if (Capacitor.isNativePlatform()) {
        // Request permission if necessary (Android)
        if (Capacitor.getPlatform() === "android") {
          const permission = await Filesystem.requestPermissions();
          if (permission.publicStorage !== "granted") {
            alert("Storage permission not granted");
            return;
          }
        }

        // Write the file to the Downloads directory
        const writeResult = await Filesystem.writeFile({
          path: fileName,
          data: base64Excel,
          directory: Directory.Downloads, // Save to Downloads folder
          encoding: Encoding.BASE64,
        });

        // Notify the user
        alert(`File saved to Downloads folder:\n${writeResult.uri}`);
      } else {
        // Fallback for web environment
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error("Error saving file", error);
      alert("Error saving file: " + error.message);
    }
  };

  return (
    <div>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft" className="logo" />
        </NavLink>
      </header>
      <main>
        <div className="transactions">
          <p>Sales Report</p>
        </div>
        <div className="goback">
          <NavLink to="/dashboard">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>
        <div className="datePicker reports-datePicker">
          {/* Date Range Picker */}
          <label htmlFor="dateRange">
            <img src={dateRange} alt="Date Range" />
          </label>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            maxDate={new Date()} // Ensure dates are not in the future
            isClearable={true}
            placeholderText="Select a date range"
          />

          {/* Download Button */}
          <button onClick={downloadExcel} className="reports-download-button">
            <img src={excelIcon} alt="Download Excel" /> Download
          </button>
        </div>
        <div
          className="item-list"
          style={{ overflowX: "auto", marginBottom: "3.5rem" }}
        >
          <table>
            <thead>
              <tr>
                <th className="farmerstyle">Farmer Name/Date</th>
                <th className="billNoStyle">Bill No.</th>
                <th className="traderStyle">Trader Name</th>
                <th className="itemStyle">Item Name / Grade Name</th>
                <th>Mark</th>
                <th className="integer text-right">Bags</th>
                <th className="decimal text-right">Weight (Kgs)</th>
                <th className="decimal text-right">Rate</th>
                <th className="decimal text-right">Amount</th>
                <th className="decimal text-right">Gunny Amount</th>
                <th className="decimal text-right">Net Amount</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedData).length > 0 ? (
                Object.keys(groupedData).map((farmerName) => {
                  const farmerData = groupedData[farmerName];
                  const farmerTotals = calculateFarmerTotals(farmerData);
                  return (
                    <React.Fragment key={farmerName}>
                      {/* Farmer name row */}
                      <tr id="tr_party_name">
                        <td colSpan="11" style={{ fontWeight: "bold" }}>
                          {farmerName}
                        </td>
                      </tr>
                      {/* Farmer's transactions */}
                      {farmerData.map((item, index) => (
                        <tr id="tr_party_body" key={index}>
                          <td>{item.billDate}</td>
                          <td>{item.billNo || item.billNo2}</td>
                          <td>{item.partyName}</td>
                          <td>
                            {item.itemName} / {item.gradeName}
                          </td>
                          <td>{item.mark}</td>
                          <td className="integer text-right">{item.bags}</td>
                          <td className="decimal text-right">
                            {parseFloat(item.weight || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.rate || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.amount || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.gAmt || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.netAmt || 0).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      {/* Farmer totals row */}
                      <tr id="tr_summary">
                        <td colSpan="5" style={{ fontWeight: "bold" }}>
                          Totals for {farmerName}
                        </td>
                        <td className="integer text-right">
                          {farmerTotals.bags}
                        </td>
                        <td className="decimal text-right">
                          {farmerTotals.weight.toFixed(2)}
                        </td>
                        <td></td>
                        <td className="decimal text-right">
                          {farmerTotals.amount.toFixed(2)}
                        </td>
                        <td className="decimal text-right">
                          {farmerTotals.gAmt.toFixed(2)}
                        </td>
                        <td className="decimal text-right">
                          {farmerTotals.netAmt.toFixed(2)}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    No sales data available for the selected date range.
                  </td>
                </tr>
              )}
              {/* Overall totals */}
              {Object.keys(groupedData).length > 0 && (
                <tr id="tr_summary">
                  <td colSpan="5" style={{ fontWeight: "bold" }}>
                    Overall Totals
                  </td>
                  <td className="integer text-right">{totals.bags}</td>
                  <td className="decimal text-right">
                    {totals.weight.toFixed(2)}
                  </td>
                  <td></td>
                  <td className="decimal text-right">
                    {totals.amount.toFixed(2)}
                  </td>
                  <td className="decimal text-right">
                    {totals.gAmt.toFixed(2)}
                  </td>
                  <td className="decimal text-right">
                    {totals.netAmt.toFixed(2)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default SalesReport;
